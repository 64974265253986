@forward "./bootstrap/bootstrap-variables"

/* There is a new file with defined colors - colors.ts.
 * Path to file: demica\libs\core\core\src\lib\styling */

/* ========== Translation from CSS vars to SASS theme vars ========== */

/* Component variables */
$th-hover: var(--trf-color-primary)
$th-background-color: var(--trf-background-color)
$th-font-color: var(--trf-font-color)

/* ========== Sass variables ========== */

/* Colors palette - TO REMOVE */
$demica-light-gray: #f6f7fa
$demica-gray: #f1f1f1
$demica-mid-gray: #d9d9d9
$demica-dark-gray: #9b9b9b
$demica-purple: #9d00ff
$demica-red: #D0021B
$demica-orange: #FF8100
$demica-pink: #DF72C1
$demica-steel-blue: #2F71B2
$demica-green: green

$trf-control-border: var(--trf-control-border)

/* Wizard */
$trf-wizard-bg: var(--trf-wizard-bg)
$trf-wizard-step: var(--trf-wizard-step)
$trf-wizard-step-current: var(--trf-wizard-step-current)

/* Inline alert */
$trf-alert-success: var(--trf-alert-success)
$trf-alert-success-border: var(--trf-alert-success-border)
$trf-alert-success-bg: var(--trf-alert-success-bg)

$trf-alert-info: var(--trf-alert-info)
$trf-alert-info-border: var(--trf-alert-info-border)
$trf-alert-info-bg: var(--trf-alert-info-bg)

$trf-alert-warning: var(--trf-alert-warning)
$trf-alert-warning-border: var(--trf-alert-warning-border)
$trf-alert-warning-bg: var(--trf-alert-warning-bg)

$trf-alert-error: var(--trf-alert-error)
$trf-alert-error-border: var(--trf-alert-error-border)
$trf-alert-error-bg: var(--trf-alert-error-bg)

/* Statuses */
$trf-status-waiting-for-approval: var(--trf-status-waiting-for-approval)
$trf-status-rejected: var(--trf-status-rejected)
$trf-status-queued: var(--trf-status-queued)
$trf-status-processing: var(--trf-status-processing)
$trf-status-failed: var(--trf-status-failed)
$trf-status-complete: var(--trf-status-complete)
$trf-status-new: var(--trf-status-new)
$trf-status-rolled-back: var(--trf-status-rolled-back)

/* Panels */
$panel-section-divider-margin: var(--panel-section-divider-margin)
$panel-margin: var(--panel-margin)
$panel-padding: var(--panel-padding)
$panel-side-margin: var(--panel-side-margin)

/* Forms */
@keyframes strokePulse
  0%
    stroke-width: 1

  33%
    stroke-width: 5

  67%
    stroke-width: 1

  100%
    stroke-width: 5

/* Images */
$trf-logo-width: var(--trf-logo-width)
$trf-logo-height: var(--trf-logo-height)
$trf-client-edit-logo-width: var(--trf-client-edit-logo-width)
$trf-client-edit-logo-height: var(--trf-client-edit-logo-height)
$trf-client-edit-logo-height-bigger: var(--trf-client-edit-logo-height-bigger)
$trf-client-edit-logo-height-smaller: var(--trf-client-edit-logo-height-smaller)

/* Alert */
$faulty-border-color: var(--faulty-border-color)
$faulty-background-color: var(--faulty-background-color)
$faulty-font-color: var(--faulty-font-color)
$faulty-icon-color: var(--faulty-icon-color)

$faultless-border-color: var(--faultless-border-color)
$faultless-background-color: var(--faultless-background-color)
$faultless-font-color: var(--faultless-font-color)
$faultless-icon-color: var(--faultless-icon-color)

/* Logo */
$trf-client-icon-max-width: var(--trf-client-icon-max-width)
$trf-client-icon-max-height: var(--trf-client-icon-max-height)

/* Accordion */
$trf-accordion-title-padding: var(--trf-accordion-title-padding)
$trf-accordion-title-height: var(--trf-accordion-title-height)
$trf-accordion-panel-bg: var(--trf-accordion-panel-bg)

/* Helper */
$trf-helper-background: var(--trf-helper-background)
$trf-helper-border: var(--trf-helper-border)
$trf-helper-arrow: var(--trf-helper-arrow)

/* Chart axis colors */
$trf-chart-axis-gray: var(--trf-chart-axis-gray)

/* ng-select filters */
$th-filter-tag-border-radius: var(--trf-filter-tag-border-radius)
$th-filter-button-padding: var(--trf-filter-button-padding)
$th-filter-button-font: var(--trf-filter-button-font)
