@use "styles/variables/sections"
@use "styles/variables/colors"
@use "styles/utility-classes"

%clearSectionSpacing
  @extend %clearHorizontalPadding
  border-bottom: 0

=setSectionsWidth($args...)
  @if (length($args) == 0)
    flex-direction: column
    .trf-section.trf-section
      width: 100%
      margin-left: 0
      margin-right: 0
  @else
    flex-direction: row
    @for $i from 1 through length($args)
      $elWidth: nth($args, $i)
      .trf-section:nth-child(#{$i})
        width: $elWidth

html
  .trf-sections-wrapper
    display: flex
    justify-content: space-between
    .trf-section
      width: 100%
      &:nth-child(odd):not(:last-child)
        margin-right: 5px
      &:nth-child(even)
        margin-left: 5px

html[dir="rtl"]
  .trf-sections-wrapper
    .trf-section
      &:nth-child(odd):not(:last-child)
        margin-right: 0
        margin-left: 5px
      &:nth-child(even)
        margin-left: 0
        margin-right: 5px

  .trf-section-header
    text-align: right

  .trf-section-content
    text-align: right

.trf-section
  background-color: sections.$th-section-bg
  margin-bottom: sections.$th-section-margin

.trf-section-header
  padding: sections.$th-section-padding
  border-bottom: sections.$th-section-header-border
  color: sections.$th-section-header-color
  font-family: sections.$th-section-header-font
  &-with-actions
    display: flex
    justify-content: space-between
    align-items: baseline
  &.no-spacing
    @extend %clearSectionSpacing
  &.tabs-header
    padding: sections.$th-section-tabs-nested-header-padding
    border-bottom: sections.$th-section-tabs-nested-header-border
    h2
      margin-bottom: sections.$th-section-tabs-nested-header-margin
  &.trf-sub-section-header
    padding: sections.$th-section-padding sections.$th-section-padding 0
  &.no-border
    border-bottom: none

$section-elements: ("content": sections.$th-section-padding, "content-nested": sections.$th-section-nested-padding, "buttons": sections.$th-section-padding)
@each $element, $padding in $section-elements
  .trf-section-#{$element}
    &:not(:empty)
      padding: $padding
      &.no-spacing
        @extend %clearSectionSpacing
