@use "styles/variables"
@use "styles/variables/transitions"
@use "styles/variables/buttons"
@use "styles/variables/icons"
@use "styles/variables/states"

.trf-actions ngp-svg-icon
  font-size: icons.$th-table-icon-size
  margin: 0 8px
  cursor: pointer
  color: icons.$th-action-icon-color
  transition: color transitions.$th-transition-speed

  &.inactive.inactive
    cursor: default
    color: icons.$th-action-icon-disabled-color

  &.processed.processed
    cursor: default
    color: icons.$th-action-icon-processed-color

  &:hover
    color: icons.$th-action-icon-hover-color
