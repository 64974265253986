@use "sass:math"

$font-size-base: 14px

$grid-columns: 12
$grid-gutter-width: 30px

$navbar-height: 52px

$line-height-base: 1.428571429
$line-height-computed: floor(($font-size-base * $line-height-base))

$navbar-padding-horizontal: floor(math.div($grid-gutter-width, 2))
$navbar-padding-vertical: (math.div(($navbar-height - $line-height-computed), 2))

// Grid
$screen-xs: 480px
$screen-xs-min: $screen-xs
$screen-sm: 768px
$screen-sm-min: $screen-sm
$screen-md: 992px
$screen-md-min: $screen-md
$screen-lg: 1200px
$screen-lg-min: $screen-lg
$screen-xs-max: ($screen-sm-min - 1)
$screen-sm-max: ($screen-md-min - 1)
$screen-md-max: ($screen-lg-min - 1)
