$th-section-bg: var(--trf-section-bg)
$th-section-margin: var(--trf-section-margin)
$th-section-padding: var(--trf-section-padding)
$th-section-nested-padding: var(--trf-section-nested-padding)
$th-section-separator-border: var(--trf-section-border)
$th-section-tabs-nested-header-padding: var(--trf-section-tabs-nested-header-padding)
$th-section-tabs-nested-header-border: var(--trf-section-tabs-nested-header-border)
$th-section-tabs-nested-header-margin: var(--trf-section-tabs-nested-header-margin)

$th-section-header-color: var(--trf-section-header-color)
$th-section-header-border: var(--trf-section-header-border)
$th-section-header-font: var(--trf-section-header-font)
$th-section-header-font-size: var(--trf-section-header-font-size)

$th-section-buttons-border: var(--trf-section-buttons-border)

/* sections-wrapper vertical row divider */
$th-sections-wrapper-row-non-divided-max-width: var(--trf-sections-wrapper-row-non-divided-max-width)
$th-sections-wrapper-row-divided-min-width: var(--trf-sections-wrapper-row-divided-min-width)