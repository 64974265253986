/* ========== Translation from CSS vars to SASS theme vars ========== */

$th-radio-size: var(--ngp-radio-size)
$th-radio-frame-border-scale: var(--ngp-radio-frame-border-scale)
$th-radio-check-border-size: var(--ngp-radio-check-border-size)

$th-radio-bg: var(--ngp-radio-bg)
$th-radio-frame-border-color: var(--ngp-radio-frame-border-color)
$th-radio-check-color: var(--ngp-radio-check-color)

$th-radio-disabled-bg: var(--ngp-radio-disabled-bg)
$th-radio-disabled-frame-border-color: var(--ngp-radio-disabled-frame-border-color)
$th-radio-disabled-check-color: var(--ngp-radio-disabled-check-color)