@use "styles/mixins/forms" as fm
@use "styles/variables/inputs"

// warnings

.trf-warning.ng-touched
  @include fm.border-warning

.trf-warning.ng-touched:focus
  @include fm.border-warning-focus

.trf-form-submitted
  .trf-warning.form-control
    @include fm.border-warning

  .trf-warning.form-control:focus
    @include fm.border-warning-focus

ng-select.ng-select.form-control.trf-custom-warning
  @include fm.border-warning

  &.ng-select-focused
    @include fm.border-warning-focus

// errors

.ng-invalid.ng-touched.form-control, ng-select.ng-select.form-control.ng-invalid.ng-touched
  @include fm.border-invalid
  @include fm.input-group-text-invalid
  .ng-select-container
    background-color: transparent

.ng-invalid.ng-touched.form-control:focus, ng-select.ng-select.form-control.ng-invalid.ng-touched.ng-select-focused:focus
  @include fm.border-invalid-focus

.trf-form-submitted
  .ng-invalid.form-control, ng-select.ng-select.form-control.ng-invalid
    @include fm.border-invalid
    @include fm.input-group-text-invalid
    .ng-select-container
      background-color: transparent

  .ng-invalid.form-control:focus, ng-select.ng-select.form-control.ng-select-focused.form-control
    @include fm.border-invalid-focus

.trf-custom-warning
  input.form-control
    @include fm.border-warning

    &:focus
      @include fm.border-warning-focus

  ng-select.ng-select.form-control
    @include fm.border-warning

    &.ng-select-focused
      @include fm.border-warning-focus

    .ng-select-container
      background-color: inputs.$th-input-warning-bg