@use "styles/variables/notifications" as variables-notifications
@use "styles/mixins/notifications" as mixins-notifications
@use "styles/variables/fonts"

toaster-container
  position: fixed
  z-index: 99999
  top: 50px
  right: 10px
  left: auto

  .toast
    display: flex
    width: variables-notifications.$th-notification-toast-width
    max-width: variables-notifications.$th-notification-toast-max-width
    margin: 10px 0
    padding: variables-notifications.$th-notification-toast-padding
    cursor: pointer
    border-radius: variables-notifications.$th-notification-border-radius

    ngp-svg-icon
      display: variables-notifications.$th-notification-icon-display
      font-size: variables-notifications.$th-notification-icon-size
      margin-right: 10px
    .notification-content
      max-width: variables-notifications.$th-notification-content-max-width
    &.toast-success
      +mixins-notifications.notification-toaster(variables-notifications.$th-notification-success, variables-notifications.$th-notification-success-bg, variables-notifications.$th-notification-success-hover-bg, variables-notifications.$th-notification-success-icon-color)

    &.toast-warning
      +mixins-notifications.notification-toaster(variables-notifications.$th-notification-warning, variables-notifications.$th-notification-warning-bg, variables-notifications.$th-notification-warning-hover-bg, variables-notifications.$th-notification-warning-icon-color)

    &.toast-error
      +mixins-notifications.notification-toaster(variables-notifications.$th-notification-error, variables-notifications.$th-notification-error-bg, variables-notifications.$th-notification-error-hover-bg, variables-notifications.$th-notification-error-icon-color)

    &-content
      display: variables-notifications.$th-notification-content-display
      align-items: variables-notifications.$th-notification-content-align-items
      padding-right: variables-notifications.$th-notification-content-padding-side

      .toast-title
        display: variables-notifications.$th-notification-title-font-display
        font-size: variables-notifications.$th-notification-title-font-size
        line-height: variables-notifications.$th-notification-title-line-height
        margin-right: variables-notifications.$th-notification-title-margin-side

        &-inline
          display: variables-notifications.$th-notification-title-inline
          font-family: fonts.$th-font-family-bold

    .toast-message
      line-height: variables-notifications.$th-notification-icon-size
      font-size: variables-notifications.$th-notification-message-font-size

    .toast-close-button
      position: absolute
      top: 0
      right: variables-notifications.$th-notification-close-btn-spacing
      background-color: transparent
      border: 0
      outline: none

      button
        font-size: variables-notifications.$th-notification-close-btn-font-size

[dir="rtl"]
  toaster-container
    right: auto
    left: 10px

    ngp-svg-icon
      margin-right: 0
      margin-left: 10px

    .toast-close-button
      left: variables-notifications.$th-notification-close-btn-spacing
      right: auto

    .toast-content
      text-align: right
      padding-right: 0
      padding-left: variables-notifications.$th-notification-content-padding-side
