@use "styles/variables/colors"
@use "styles/variables/transitions"

.accordion
  .card
    border: none
    border-radius: 0
    margin-top: .75rem
    .card-header
      background-color: colors.$th-color-white
      border-radius: 0
      padding-left: 0
      padding-right: 0
      padding-top: 0
      border-bottom: none
      h5 > button
        display: block
        width: 100%
        padding: 0
        text-align: inherit
        color: inherit
        text-decoration: none

        .accordion-collapse-icon
          margin-right: .5rem
          transition: transform transitions.$th-transition-speed transitions.$th-transition-fn
        &.collapsed
          .accordion-collapse-icon
            transform: rotate(-90deg)

  .card-body
    padding: .5rem 0

/* rtl */
[dir="rtl"]
  .card
    .card-header
      h5 > button
        .accordion-collapse-icon
          margin-left: .5rem
          margin-right: 0
        &.collapsed
          .accordion-collapse-icon
            transform: rotate(90deg)
