@use "styles/variables/charts"

.chart-btns
  display: flex
  justify-content: space-between
  align-items: flex-end
  padding: 10px 0 0

// a global style must be added because the c3 library creates a dummy element on the body when measuring the size of a box
 // currently style are added to chart component directly
 // ref: c3.js method 'ChartInternal.prototype.getTextRect'
.c3 .c3-legend-item
 font-size: charts.$th-chart-legend-item-font-size
 text
   font-size: charts.$th-chart-legend-item-font-size