// Margins for buttons when lied one after another
ngp-button
  &:not(:last-of-type)
    margin-right: 5px

[dir="rtl"]
  ngp-button
    &:not(:last-of-type)
      margin-right: 0
      margin-left: 5px
