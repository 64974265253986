@use "styles/variables"
@use "styles/variables/modal"
@use "styles/variables/colors"

$half-gutter: 15px

.trf-panel
  margin: variables.$panel-margin 0
  padding: variables.$panel-padding
  background-color: modal.$th-modal-section-bg
  border: 1px solid modal.$th-modal-section-border-color
  overflow: hidden

.trf-grid-panel
  margin: variables.$panel-margin 0
  padding: variables.$panel-padding 0 0 0
  background-color: modal.$th-modal-section-bg
  border: 1px solid modal.$th-modal-section-border-color

  .trf-panel-section
    margin: 0 $half-gutter 15px $half-gutter

.trf-panel-section
  border-bottom: 1px solid darken(variables.$demica-gray, 5%)
  margin-bottom: 15px
  color: colors.$th-color-primary-light

.trf-panel-section-content
  font-size: .9rem
