@use "styles/variables/modal"
@use "styles/variables/colors"
@use "styles/variables/transitions"

.modal-open
  overflow: hidden
  // Add white background layer under opened modal / slidein for IE 10-11
  @media all and (-ms-high-contrast: none)
    &::before
      content: ''
      position: fixed
      top: 0
      left: 0
      right: 0
      bottom: 0
      background-color: modal.$th-modal-backdrop-color
      opacity: modal.$th-modal-backdrop-opacity
      z-index: 1040

// ngb-modal-backdrop
.modal-backdrop
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1040
  background-color: transparent
  &:last-of-type
    background-color: modal.$th-modal-backdrop-color

  .shadow
    opacity: .85

  &.fade.show
    opacity: modal.$th-modal-backdrop-opacity

.fade
  opacity: 0
  transition: opacity transitions.$th-transition-speed transitions.$th-transition-fn

// ngb-modal-window
.modal-open .modal
  overflow-x: hidden
  overflow-y: auto

.fade.show
  opacity: 1

.modal
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1050
  display: none
  overflow: hidden
  outline: 0

.modal.vertical-middle
  display: flex !important // overrides default bootstrap dialog d-block !important
  align-items: center

.modal.fade .modal-dialog
  transition: transform .3s ease-out

.trf-modal-wide
  .modal-dialog
    min-width: 750px

.trf-modal-controls-divider
  border-top: 1px solid modal.$th-modal-control-divider

@media(min-width: 576px)
  .modal-dialog
    margin: 30px auto

.modal-dialog
  position: relative
  width: auto

.modal-content
  position: relative
  display: flex
  flex-direction: column
  background-color: modal.$th-modal-bg
  background-clip: padding-box
  border: modal.$th-modal-border
  border-radius: modal.$th-modal-border-radius
  outline: 0
  box-shadow: 0 modal.$th-modal-shadow-y-offset modal.$th-modal-shadow-blur modal.$th-modal-shadow-color

//  default modal internal styles:
.modal-header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 15px
  border-bottom: 1px solid modal.$th-modal-section-border-color

.modal-body
  position: relative
  flex: 1 1 auto
  padding: 15px

.modal-footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 15px
  border-top: 1px solid modal.$th-modal-section-border-color

.modal-content > *
  min-height: 1px     //fix for ie image scaling issue
.confirmation-delete-max-amount-backdrop
  // to overide slidein.sass important backdrop z-index
  z-index: 1061 !important
