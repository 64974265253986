@use "styles/bootstrap/mixins/vendor-prefixes"
@use "styles/variables"
@use "styles/variables/fonts"
@use "styles/variables/header"
@use "styles/variables/menu-aside"
@use "styles/variables/transitions"
@use "styles/variables/buttons"
@use "styles/variables/states"

.main-header
  position: relative
  max-height: 100px
  z-index: 1030
  color: header.$th-header-font-color

  .navbar
    margin-bottom: 0
    margin-left: menu-aside.$th-sidebar-width
    border-bottom: 1px solid variables.$demica-gray
    min-height: variables.$navbar-height
    border-radius: 0
    transition: margin-left transitions.$th-transition-speed transitions.$th-transition-fn

    .sidebar-collapse &
      margin-left: 0

      .menu-toggle-icon
        transform: rotate(-90deg)

  .sidebar-menu-toggle
    background-color: header.$th-header-background-color
    margin-right: auto

    button
      cursor: pointer
      border: none
      background-color: header.$th-header-background-color
      .menu-toggle-icon
        color: header.$th-header-toggle-button-color
        transform: rotate(0deg)
        transition: transform transitions.$th-transition-speed transitions.$th-transition-fn

  .navbar-custom-menu
    float: right
    padding-right: 10px

  .navbar .nav > li > a > .label
    position: absolute
    top: 9px
    right: 7px
    text-align: center
    font-size: 9px
    padding: 2px 3px
    line-height: .9

  .logo
    display: block
    float: left
    height: variables.$navbar-height
    text-align: header.$th-header-logo-align
    width: menu-aside.$th-sidebar-width
    padding: 0 25px
    overflow: hidden
    border-right: menu-aside.$th-menu-aside-border
    transition: width transitions.$th-transition-speed transitions.$th-transition-fn, padding transitions.$th-transition-speed transitions.$th-transition-fn
    //Add support to sidebar mini by allowing the user to create
    //2 logo designs. mini and lg
    .logo-lg
      //should be visibile when sidebar isn't collapsed
      display: block

    .sidebar-collapse &
      width: menu-aside.$th-sidebar-collapse-width
      padding: 0 15px

.trf-header-link
  font-family: fonts.$th-font-family-semi-bold
  cursor: pointer
  text-decoration: none

  &.signout
    color: header.$th-header-signout-icon-color

    ngp-svg-icon
      padding-left: 2px

.trf-header-btn
  background-color: transparent
  cursor: pointer
  border: none

  &:hover
    border: none
    outline: none

  .user-circle
    font-size: 2.4rem
    vertical-align: middle
    margin-right: 10px
    color: header.$th-header-toggle-button-color

  .dropdown-chevron
    color: header.$th-header-chevron-icon-color
    transform: rotate(0deg)
    transition: transform transitions.$th-transition-speed transitions.$th-transition-fn

    &.dropdownVisible
      transform: rotate(180deg)

  span
    margin-right: 5px

html[dir="rtl"]
  .navbar
    margin-left: 0

  .sidebar-menu-toggle
    margin-right: 0
    margin-left: 5px

  .trf-header-link
    &.signout
      ngp-svg-icon
        padding-left: 0
        padding-right: 2px

  .trf-header-btn
    .user-circle
      margin-right: 0
      margin-left: 10px

    span
      margin-right: 0
      margin-left: 5px

  .navbar-nav
    padding-right: 0

  .navbar.navbar-static-top
    flex-direction: row

  .header-left
    flex-direction: row

    button
      .menu-toggle-icon
        transform: rotate(0deg)

    .actual-view-name
      padding-left: 0
      padding-right: 10px

  .logo
    float: right
    border-right: none
    border-left: menu-aside.$th-menu-aside-border

@media (min-width: variables.$screen-lg)
  .funding-alerts
    display: block
