@use "styles/variables/inputs"

// warnings

@mixin border-warning
  border: inputs.$th-input-warning-border
  box-shadow: inputs.$th-input-warning-border-box-shadow
  background-color: inputs.$th-input-warning-bg

@mixin border-warning-focus
  border-color: inputs.$th-input-warning-border-focus
  box-shadow: inputs.$th-input-warning-border-focus-box-shadow

// errors

@mixin border-invalid
  border: inputs.$th-input-invalid-border
  box-shadow: inputs.$th-input-invalid-border-box-shadow
  background-color: inputs.$th-input-invalid-bg

@mixin border-invalid-focus
  border: inputs.$th-input-invalid-border-focus
  box-shadow: inputs.$th-input-invalid-border-focus-box-shadow

@mixin input-group-text-invalid
  & + .input-group-append
    .input-group-text
      background-color: inputs.$th-input-group-invalid-bg
      border-color: inputs.$th-input-group-invalid-border-color
