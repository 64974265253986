/* ========== Translation from CSS vars to SASS theme vars ========== */

$th-checkbox-frame-color: var(--ngp-checkbox-frame-color)
$th-checkbox-frame-radius: var(--ngp-checkbox-frame-radius)
$th-checkbox-frame-thickness: var(--ngp-checkbox-frame-thickness)
$th-checkbox-frame-size: var(--ngp-checkbox-frame-size)

$th-checkbox-tick-color: var(--ngp-checkbox-tick-color)

$th-checkbox-square-color: var(--ngp-checkbox-square-color)

$th-checkbox-indeterminate-color:var(--ngp-checkbox-indeterminate-color)
$th-checkbox-indeterminate-thickness: var(--ngp-checkbox-indeterminate-thickness)
$th-checkbox-indeterminate-top: var(--ngp-checkbox-indeterminate-top)
$th-checkbox-indeterminate-width: var(--ngp-checkbox-indeterminate-width)

$th-checkbox-margin-between: var(--ngp-checkbox-margin-between)
