/* ========== Translation from CSS vars to SASS theme vars ========== */

$th-font-family-regular: var(--trf-font-family-regular)
$th-font-family-semi-bold: var(--trf-font-family-semi-bold)
$th-font-family-bold: var(--trf-font-family-bold)

$th-font-size-base: var(--trf-font-size-base)
$th-font-size-h1: var(--trf-font-size-h1)
$th-font-size-h2: var(--trf-font-size-h2)
$th-font-size-h3: var(--trf-font-size-h3)
$th-font-size-h4: var(--trf-font-size-h4)
$th-font-size-h5: var(--trf-font-size-h5)
$th-font-size-body: var(--trf-font-size-body)
$th-font-size-sm: var(--trf-font-size-sm)
$th-font-size-xs: var(--trf-font-size-xs)
$th-font-size-xxs: var(--trf-font-size-xxs)

$th-font-weight-normal: 400
$th-font-weight-bold: 700
