=dragAndDropGridElement($rowGap: 5px)
    display: grid
    grid-template-columns: inherit
    grid-column-start: 1
    grid-column-end: inherit
    row-gap: $rowGap
    list-style-type: none
    width: inherit

=dragAndDropVerticalLine($border)
    position: absolute
    content: ""
    top: -5px
    border-left: $border
    border-radius: 0 0 0 0
    width: 1px
    height: calc(100% + 10px)

=dragAndDropHorizontalLine($border, $width, $rtl: false)
    content: ""
    position: absolute
    top: 23px
    border-left: $border
    border-bottom: $border
    border-radius: 0 0 0 0
    width: $width
    height: 1px

    @if $rtl
        right: calc($width * -1)
    @else
        left: calc($width * -1)

=dragAndDropPlaceholderInsert($maxLevel, $padding, $rtl: false)
    .cdk-drag-placeholder
        @for $i from 1 through $maxLevel
            &.ngp-dnd-element-#{$i}
                > .ngp-dnd-list
                    @if $rtl
                        margin-right: calc(-1 * $i * $padding)
                        margin-left: 0
                    @else
                        margin-left: calc(-1 * $i * $padding)
