@use "styles/variables"
@use "styles/variables/menu-aside"
@use "styles/variables/transitions"
@use "styles/variables/core"

$header-height: 56px
$transition-rule: margin transitions.$th-transition-speed transitions.$th-transition-fn

/* Fixed layout */
.fixed.fixed
  .main-header,
  .main-sidebar,
  .left-side
    position: fixed

  .main-header
    top: 0
    right: 0
    left: 0

  .content-wrapper,
  .right-side
    padding-top: $header-height
    @media (max-width: variables.$screen-xs-max)
      padding-top: 100px

  &.layout-boxed
    .wrapper
      max-width: 100%

  .wrapper
    overflow: hidden

.content-wrapper
  margin-left: menu-aside.$th-sidebar-width
  z-index: 820
  transition: $transition-rule

  .sidebar-collapse &
    @media (min-width: variables.$screen-sm)
      margin-left: menu-aside.$th-sidebar-collapse-width

  .sidebar-open &
    @media (max-width: variables.$screen-xs-max)
      transform: translateX(menu-aside.$th-sidebar-width)

.content-wrapper
  min-height: 100%
  background-color: variables.$th-background-color
  margin-left: menu-aside.$th-sidebar-width
  z-index: 800
  transition: $transition-rule

  > .content
    position: relative
    min-height: calc(100vh - #{$header-height})
    padding: core.$th-main-content-padding

  .sidebar-collapse &
    @media (min-width: variables.$screen-sm)
      margin-left: menu-aside.$th-sidebar-collapse-width

  .sidebar-open &
    @media (max-width: variables.$screen-xs-max)
      transform: translateX(menu-aside.$th-sidebar-width)

html[dir="rtl"]
  .content-wrapper
    margin-left: 0
    margin-right: menu-aside.$th-sidebar-width

  .sidebar-collapse .content-wrapper
    @media (min-width: variables.$screen-sm)
      margin-left: 0
      margin-right: menu-aside.$th-sidebar-collapse-width

  .sidebar-open .content-wrapper
    @media (max-width: variables.$screen-xs-max)
      transform: translateX(menu-aside.$th-sidebar-width)
