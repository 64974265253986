/* Charts colors */
/* Files */
/*  `demica\libs\core\core\src\lib\styling\colors.ts` */

\:root
  --chart-reserve-xs: #6e50ff
  --chart-reserve-xs-missing: #8a72ff
  --chart-reserve-loss: #2f71b2
  --chart-reserve-loss-missing: #5291ce
  --chart-reserve-dilution: #df72c1
  --chart-reserve-dilution-missing: #f088d3
  --chart-reserve-yield: #60c3ad
  --chart-reserve-yield-missing: #79dfc8
  --chart-reserve-fx: #9fc553
  --chart-reserve-fx-missing: #bee277
  --chart-others-1-basic: #00A5CF
  --chart-others-1-missing: #23c0e9
  --chart-others-2-basic: #e0d230
  --chart-others-2-missing: #f8e71c
  --chart-others-3-basic: #9c0013
  --chart-others-3-missing: #d0021b
  --chart-others-4-basic: #c98c30
  --chart-others-4-missing: #ffb441
  --chart-others-5-basic: #46c2c5
  --chart-others-5-missing: #57e2e5
  --chart-others-6-basic: #7280ac
  --chart-others-6-missing: #8c9dd2
  --chart-others-7-basic: #868686
  --chart-others-7-missing: #bfbfbf
  --chart-others-8-basic: #44c37e
  --chart-others-8-missing: #54f29d
  --chart-floor: #000

  --chart-legend-font: .8rem sans-serif
