@use "styles/mixins/slide-in-animations"
@use "styles/variables"
@use "styles/variables/slidein"

$initialBackdropZindex: 1040
$initialModalZindex: 1050

// ngb-modal-backdrop gets z-index from library as inline style. !important here is the only way to overwrite it z-index: $initialBackdropZindex !important
@for $i from 1 through 5
  ngb-modal-backdrop:nth-of-type(#{$i})
    z-index: $initialBackdropZindex + ($i * 10) !important
  ngb-modal-window:nth-of-type(#{$i})
    z-index: $initialModalZindex + ($i * 10) !important

body:not(.dialog-open) ngb-modal-window.trf-slidein
  right: slidein.$th-slidein-child-shift
  &:last-of-type
    right: 0

html[dir="rtl"]
  body:not(.dialog-open) ngb-modal-window.trf-slidein
    right: auto
    left: slidein.$th-slidein-child-shift
    &:last-of-type
      left: 0

//SLIDEIN
.modal-open
  .trf-slidein
    left: auto
    width: auto
    .modal-dialog
      margin: 0 0 0 auto
      max-width: 100vw

    .modal-content
      border: none
      background-color: transparent

    .tab-header
      padding-bottom: 1.5rem

    .slidein-section-separator
      margin-top: 2rem

  .trf-slidein-footer
    padding: slidein.$th-slidein-footer-padding
    border-top: slidein.$th-slidein-footer-border

.form-glass-applier
  position: relative
  &::after
    content: ''
    position: absolute
    top: -15px
    left: -15px
    right: -15px
    bottom: 0
    background-color: transparent
    transform: translateX(100%)
    transition: transform .3s ease-in-out, background-color .3s ease-in-out

  &.apply-glass
    &::after
      background-color: slidein.$th-slidein-glass-applier-bg
      transform: translateX(0)
      z-index: 10

/* rtl */
[dir="rtl"]
  .trf-slidein
    left: 0
    right: auto
