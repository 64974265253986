@use "libs/design-system/sass/components-core/notifications/notifications-vars"

@mixin notification-toaster($color, $bg-color, $bg-hover-color, $icon-color)
  color: $color
  background-color: $bg-color

  ngp-svg-icon
    color: $icon-color

  &:hover
    background-color: $bg-hover-color

  .toast-close-button
    color: $color

=getNotificationIconStyles($color: inherit)
  color: $color
  margin-right: notifications-vars.$th-inline-notification-msg-icon-margin-right
  \:host-context([dir="rtl"])
    .icon
      margin-left: 8px
      margin-right: 0

=contextNotification($bg-color, $font-color, $border-color )
  background-color: $bg-color
  color: $font-color
  border-color: $border-color
  \:host-context([dir="rtl"])
    .context-notification
      text-align: right
