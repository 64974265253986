// Taken from http://lipis.github.io/flag-icon-css/

// VARIABLES
$flag-icon-css-path: '/assets/flags'
$flag-icon-rect-path: '/4x3'
$flag-icon-square-path: '/1x1'

// Base

=flag-icon($country)
  .flag-icon-#{$country}
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg)
    &.flag-icon-squared
      background-image: url(#{$flag-icon-css-path}#{$flag-icon-square-path}/#{$country}.svg)

.flag-icon-background
  background-size: contain
  background-position: 50%
  background-repeat: no-repeat

.flag-icon
  @extend .flag-icon-background
  position: relative
  display: inline-block
  width: 1.33em
  line-height: 1em

  &:before
    content: "\00a0"

  &.flag-icon-squared
    width: 1em

@include flag-icon(gb)
@include flag-icon(es)
@include flag-icon(ru)
@include flag-icon(ro)
@include flag-icon(fr)
@include flag-icon(ca)
@include flag-icon(it)
@include flag-icon(de)
@include flag-icon(pl)
@include flag-icon(el)
@include flag-icon(pt)
@include flag-icon(sk)
@include flag-icon(cz)
@include flag-icon(bh)
@include flag-icon(us)
