@use "styles/variables/inputs"

.validation-error
  line-height: 1.1
  margin: 5px 0 0 3px
  min-height: inputs.$th-form-validation-msg-height

html[dir="rtl"]
  .validation-error
    margin-right: 3px
    margin-left: 0

.form-control
  padding: 4px 8px
  border: inputs.$th-input-border
  border-radius: inputs.$th-input-border-radius
  height: calc(2.25rem + 2px)
  color: inputs.$th-input-color

  + .input-group-append
    .input-group-text
      color: inputs.$th-input-group-color
      padding-block: 0
  &:disabled
    background-color: inputs.$th-input-disabled-bg
    border: inputs.$th-input-disabled-border
    color: inputs.$th-input-disabled-color
    + .input-group-append
      .input-group-text
        background-color: inputs.$th-input-group-disabled-bg
        border-top-color: inputs.$th-input-group-disabled-border-color
        border-right-color: inputs.$th-input-group-disabled-border-color
        border-bottom-color: inputs.$th-input-group-disabled-border-color
        color: inputs.$th-input-disabled-color
        cursor: none
        border-left-color: transparent

    &::placeholder
      color: inputs.$th-input-disabled-placeholder-color

  &:focus-visible
    &::placeholder
      text-shadow: none

  &::placeholder
    color: inputs.$th-input-placeholder-color

input.form-control
  &:read-only
    background: inputs.$th-input-disabled-bg

[dir="rtl"]
  .input-group-append.input-group-append
    margin-right: -2px
  .form-control:disabled + .input-group-append
    .input-group-text
      background-color: inputs.$th-input-group-disabled-bg
      border-top-color: inputs.$th-input-group-disabled-border-color
      border-left-color: inputs.$th-input-group-disabled-border-color
      border-bottom-color: inputs.$th-input-group-disabled-border-color
      border-right-color: transparent

input[type="text"], input[type="number"], select
  &.form-control
    font-size: inputs.$th-input-font-size

.input-group-text
  border-radius: inputs.$th-input-border-radius
  background-color: inputs.$th-input-group-bg
  border-color: inputs.$th-input-group-border-color

