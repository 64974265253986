$th-chart-axis-display: var(--trf-chart-axis-display)
$th-chart-axis-text-color: var(--trf-chart-axis-text-color)
$th-chart-grid-lines-color: var(--trf-chart-grid-lines-color)
$th-chart-grid-text-color: var(--trf-chart-grid-text-color)
$th-chart-legend-item-font-size: var(--trf-chart-legend-item-font-size)
$th-chart-legend-item-icon-stroke-linecap: var(--trf-chart-legend-item-icon-stroke-linecap)
$th-chart-legend-item-icon-stroke-width: var(--trf-chart-legend-item-size)
$th-chart-legend-item-size: var(--trf-chart-legend-item-size)
$th-chart-legend-item-text-rotate: var(--trf-chart-legend-item-text-rotate)
$th-chart-pie-legend-bg: var(--trf-chart-pie-legend-bg)
$th-chart-pie-legend-entry-top-margin: var(--trf-chart-pie-legend-entry-top-margin)
$th-chart-pie-legend-icon-radius: var(--trf-chart-pie-legend-icon-radius)
$th-chart-tick-axis-color: var(--trf-chart-tick-axis-color)
$th-chart-tick-axis-font-size: var(--trf-chart-tick-axis-font-size)
$th-chart-tooltip-color: var(--trf-chart-tooltip-color)
$th-chart-tooltip-font-size: var(--trf-chart-tooltip-font-size)
$th-chart-tooltip-border: var(--trf-chart-tooltip-border)
$th-chart-tooltip-header-font-size: var(--trf-chart-tooltip-header-font-size)
$th-chart-tooltip-header-bg: var(--trf-chart-tooltip-header-bg)
$th-chart-tooltip-header-color: var(--trf-chart-tooltip-header-color)
$th-chart-tooltip-header-font-weight: var(--trf-chart-tooltip-header-font-weight)
$th-chart-tooltip-header-padding: var(--trf-chart-tooltip-header-padding)


$th-chart-last-value-component-consolidate-amount-font-size: var(--trf-chart-last-value-component-consolidate-amount-font-size)
$th-chart-last-value-component-consolidate-amount-font-family: var(--trf-chart-last-value-component-consolidate-amount-font-family)
$th-chart-last-value-component-consolidate-amount-value-margin: var(--trf-chart-last-value-component-consolidate-amount-value-margin)
$th-chart-last-value-component-user-currency-color: var(--trf-chart-last-value-component-user-currency-color)
$th-chart-last-value-component-user-currency-font-size: var(--trf-chart-last-value-component-user-currency-font-size)
$th-chart-last-value-component-currency-item-color: var(--trf-chart-last-value-component-original-currency-color)
$th-chart-last-value-component-currency-font-size: var(--trf-chart-last-value-component-original-currency-font-size)
