$drag-and-drop-item-border: 1px solid var(--ngp-drag-and-drop-item-border-color)
$drag-and-drop-border-spacing: 3px

.drag-and-drop-table
  width: 100%
  border-collapse: separate
  border-spacing: 0 $drag-and-drop-border-spacing

  .col-handler
    width: 50px
    text-align: center

  &.cdk-drop-list-dragging,
  &.cdk-drop-list-receiving
    border: 1px dashed var(--ngp-drag-and-drop-zone-border-color)
    border-radius: var(--ngp-drag-and-drop-zone-border-radius)
    padding: 8px

  &.cdk-drop-list-dragging
    margin-bottom: -$drag-and-drop-border-spacing
    background: var(--ngp-drag-and-drop-zone-background-color-active)

.drag-and-drop-row
  cursor: move

  &.preview-mode
    cursor: default

  td
    background: var(--ngp-drag-and-drop-item-background-color)
    border-top: $drag-and-drop-item-border
    border-bottom: $drag-and-drop-item-border
    padding: 11px 0

    &.col-handler
      color: var(--ngp-drag-and-drop-item-handler-color)

    &:first-child
      border-left: $drag-and-drop-item-border
      border-radius: var(--ngp-drag-and-drop-item-border-radius) 0 0 var(--ngp-drag-and-drop-item-border-radius)

    &:last-child
      border-right: $drag-and-drop-item-border
      border-radius: 0 var(--ngp-drag-and-drop-item-border-radius) var(--ngp-drag-and-drop-item-border-radius) 0

  &.cdk-drag-placeholder
    opacity: 0.5

  &.cdk-drag-preview
    color: var(--trf-color-black)
    display: table
    background: transparent

    *:not(:first-of-type)
      display: table-cell

    td
      border-color: var(--ngp-drag-and-drop-item-border-color-active)
      background-color: var(--ngp-drag-and-drop-item-background-color-active)

[dir="rtl"]
  .drag-and-drop-row
    td
      &:first-child
        border-left: none
        border-right: $drag-and-drop-item-border
        border-radius: 0 var(--ngp-drag-and-drop-item-border-radius) var(--ngp-drag-and-drop-item-border-radius) 0

      &:last-child
        border-left: $drag-and-drop-item-border
        border-right: none
        border-radius: var(--ngp-drag-and-drop-item-border-radius) 0 0 var(--ngp-drag-and-drop-item-border-radius)

    &.cdk-drag-preview
      td
        border-color: var(--ngp-drag-and-drop-item-border-color-active)