/* ========== Translation from CSS vars to SASS theme vars ========== */
$th-notification-toast-width: var(--ngp-notification-toast-width)
$th-notification-toast-max-width: var(--ngp-notification-toast-max-width)
$th-notification-content-max-width: var(--ngp-notification-content-max-width)
$th-notification-border-radius: var(--ngp-notigication-border-radius)
$th-notification-icon-size: var(--ngp-notification-icon-size)
$th-notification-toast-padding: var(--ngp-notification-toast-padding)

$th-notification-content-display: var(--ngp-notification-content-display)
$th-notification-content-align-items: var(--ngp-notification-content-align-items)
$th-notification-content-padding-side: var(--ngp-notification-content-padding-side)

$th-notification-title-font-size: var(--ngp-notification-title-font-size)
$th-notification-title-line-height: var(--ngp-notification-title-line-height)
$th-notification-title-margin-side: var(--ngp-notification-title-margin-side)
$th-notification-title-font-display: var(--ngp-notification-title-font-display)

$th-notification-title-inline: var(--ngp-notification-title-inline)

$th-notification-message-line-height: var(--ngp-notification-message-line-height)
$th-notification-message-font-size: var(--ngp-notification-message-font-size)

$th-notification-close-btn-spacing: var(--ngp-notification-close-btn-spacing)
$th-notification-close-btn-font-size: var(--ngp-notification-close-btn-font-size)

$th-notification-icon-display: var(--ngp-notification-display)
$th-notification-success: var(--ngp-notification-success)
$th-notification-success-icon-color: var(--ngp-notification-success-icon-color)
$th-notification-success-bg: var(--ngp-notification-success-bg)
$th-notification-success-hover-bg: var(--ngp-notification-success-hover-bg)

$th-notification-warning: var(--ngp-notification-warning)
$th-notification-warning-bg: var(--ngp-notification-warning-bg)
$th-notification-warning-icon-color: var(--ngp-notification-warning-icon-color)
$th-notification-warning-hover-bg: var(--ngp-notification-warning-hover-bg)

$th-notification-error: var(--ngp-notification-error)
$th-notification-error-bg: var(--ngp-notification-error-bg)
$th-notification-error-icon-color: var(--ngp-notification-error-icon-color)
$th-notification-error-hover-bg: var(--ngp-notification-error-hover-bg)

$th-inline-notification-msg-icon-display: var(--ngp-inline-notification-msg-icon-display)
$th-inline-notification-msg-icon-dark-background-color: var(--ngp-inline-notification-msg-icon-dark-background-color)
$th-inline-notification-msg-icon-error-color: var(--ngp-inline-notification-msg-icon-error-color)
$th-inline-notification-msg-icon-warning-color: var(--ngp-inline-notification-msg-icon-warning-color)

$th-inline-notification-msg-warning-color: var(--ngp-inline-notification-msg-warning-color, inherit)
$th-inline-notification-msg-color: var(--ngp-inline-notification-msg-color, inherit)
$th-inline-notification-msg-height: var(--ngp-inline-notification-msg-height)
$th-inline-notification-font-size: var(--ngp-inline-notification-font-size)


$th-context-notification-font-size: var(--ngp-context-notification-font-size)
$th-context-notification-border-radius: var(--ngp-context-notification-border-radius)
$th-context-notification-padding: var(--ngp-context-notification-padding)
$th-context-notification-border: var(--ngp-context-notification-border)
$th-context-notification-icon-display: var(--ngp-context-notification-icon-display)
$th-context-notification-icon-size: var(--ngp-context-notification-icon-size)
$th-context-notification-margin: var(--ngp-context-notification-margin)

$th-context-notification-warning-bg: var(--ngp-context-notification-warning-bg)
$th-context-notification-warning-border-color: var(--ngp-context-notification-warning-border-color)
$th-context-notification-warning-icon-color: var(--ngp-context-notification-warning-icon-color)
$th-context-notification-warning-font-color: var(--ngp-context-notification-warning-font-color)

$th-context-notification-error-bg: var(--ngp-context-notification-error-bg)
$th-context-notification-error-border-color: var(--ngp-context-notification-error-border-color)
$th-context-notification-error-icon-color: var(--ngp-context-notification-error-icon-color)
$th-context-notification-error-font-color: var(--ngp-context-notification-error-font-color)

$th-context-notification-info-bg: var(--ngp-context-notification-info-bg)
$th-context-notification-info-border-color: var(--ngp-context-notification-info-border-color)
$th-context-notification-info-icon-color: var(--ngp-context-notification-info-icon-color)
$th-context-notification-info-font-color: var(--ngp-context-notification-info-font-color)
