/* colors */
$th-input-disabled-border-color: var(--trf-input-disabled-border-color)
$th-input-disabled-bg-color: var(--trf-input-disabled-bg-color)

/* normal */
$th-input-font-size: var(--trf-input-font-size)
$th-input-color: var(--trf-input-color)
$th-input-border: var(--trf-input-border)
$th-input-border-radius: var(--trf-input-border-radius)
$th-input-border-focus: var(--trf-input-border-focus)
$th-input-placeholder-color: var(--trf-input-placeholder-color)
$th-input-icon-color: var(--trf-input-icon-color)

/* invalid */
$th-input-invalid-border: var(--trf-input-invalid-border)
$th-input-invalid-border-focus: var(--trf-input-invalid-border-focus)
$th-input-invalid-border-box-shadow: var(--trf-input-invalid-border-box-shadow)
$th-input-invalid-border-focus-box-shadow: var(--trf-input-invalid-border-focus-box-shadow)
$th-input-invalid-bg: var(--trf-input-invalid-border-bg)

/* disabled */
$th-input-disabled-border: var(--trf-input-disabled-border)
$th-input-disabled-bg: var(--trf-input-disabled-bg)
$th-input-disabled-color: var(--trf-input-disabled-color)
$th-input-disabled-placeholder-color: var(--trf-input-disabled-placeholder-color)

/* warning */
$th-input-warning-border: var(--trf-input-warning-border)
$th-input-warning-border-box-shadow: var(--trf-input-warning-border-box-shadow)
$th-input-warning-border-focus: var(--trf-input-warning-border-focus)
$th-input-warning-border-focus-box-shadow: var(--trf-input-warning-border-focus-box-shadow)
$th-input-warning-bg: var(--trf-input-warning-bg)

$th-input-group-color: var(--trf-input-group-color)
$th-input-group-bg: var(--trf-input-group-bg)
$th-input-group-border-color: var(--trf-input-group-border-color)
$th-input-group-invalid-bg: var(--trf-input-group-invalid-bg)
$th-input-group-invalid-border-color: var(--trf-input-group-invalid-border-color)
$th-input-group-disabled-bg: var(--trf-input-group-disabled-bg)
$th-input-group-disabled-border-color: var(--trf-input-group-disabled-border-color)
/* misc */

$th-form-validation-msg-height: var(--trf-form-validation-msg-height)
$th-input-group-form-telephone-row-content-offset: var(--trf-input-group-form-telephone-row-content-offset)
$th-input-group-form-telephone-row-height: var(--trf-input-group-form-telephone-row-height)
$th-input-group-form-telephone-row-top: var(--trf-input-group-form-telephone-row-top)
$th-input-group-form-telephone-color: var(--trf-input-group-form-telephone-color)
