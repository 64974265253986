@use "styles/variables/dropdowns"
@use "styles/variables/fonts"
@use "styles/variables/inputs"
@import "@ng-select/ng-select/themes/default.theme.css"

.ng-select
  font-size: dropdowns.$th-dropdown-font-size
  &.form-control
    padding: 0
    border: dropdowns.$th-dropdown-border
    border-radius: dropdowns.$th-dropdown-border-radius

    .ng-select-container.ng-select-container
      padding-top: 1px
      min-height: 100%
      height: 100%
      border-radius: dropdowns.$th-dropdown-border-radius
      border: none

    &.ng-select-multiple
      .ng-select-container.ng-select-container
        padding-top: 3px

  &.arrow-hidden .ng-arrow-wrapper
    display: none

  &.chips-input .ng-clear-wrapper
    padding-right: 5px
    width: 25px
    text-align: center
    font-weight: bold

  .ng-select-container .ng-value-container .ng-placeholder.ng-placeholder
    color: dropdowns.$th-dropdown-placeholder-color

  .ng-select-container .ng-value-container .ng-value .ng-value-label
    color: dropdowns.$th-dropdown-color
    &.label-disabled
      color: dropdowns.$th-dropdown-disabled-option-color

  .ng-arrow-wrapper
    .ng-arrow
      border-top-color: dropdowns.$th-dropdown-arrow-color

  &.ng-select-opened
    .ng-arrow-wrapper
      .ng-arrow
        border-bottom-color: dropdowns.$th-dropdown-arrow-color

  &.ng-select-disabled.ng-select-disabled
    color: dropdowns.$th-dropdown-disabled-color
    border-color: dropdowns.$th-dropdown-disabled-border-color

    .ng-select-container
      background-color: dropdowns.$th-dropdown-disabled-bg

      .ng-value-container
        .ng-placeholder.ng-placeholder
          color: dropdowns.$th-dropdown-disabled-placeholder-color
        .ng-value.ng-value .ng-value-label
          color: dropdowns.$th-dropdown-disabled-color

    .ng-arrow-wrapper
      .ng-arrow
        border-top-color: dropdowns.$th-dropdown-disabled-border-color

  &.ng-select-disabled.ng-select-readonly
    color: inputs.$th-input-color
    border: inputs.$th-input-border

    .ng-select-container .ng-value-container .ng-value.ng-value .ng-value-label
      color: inputs.$th-input-color

  ng-dropdown-panel
    font-size: dropdowns.$th-dropdown-font-size
    &.ng-dropdown-panel
      left: -1px
      width: calc(100% + 2px)
      border-radius: dropdowns.$th-dropdown-border-radius
      border-color: dropdowns.$th-dropdown-border-color
      color: dropdowns.$th-dropdown-color

      .ng-dropdown-panel-items .ng-option
        padding: dropdowns.$th-dropdown-option-padding
        color: dropdowns.$th-dropdown-color

        &.ng-option-marked
          background-color: dropdowns.$th-dropdown-highlight-item-bg
          color: dropdowns.$th-dropdown-highlight-item-color

        &.ng-option-selected
          background-color: dropdowns.$th-dropdown-selected-item-bg
          color: dropdowns.$th-dropdown-selected-item-color
          border-left: dropdowns.$th-dropdown-selected-item-border

        &.ng-option-disabled
          color: dropdowns.$th-dropdown-disabled-option-color

/* multiselect */
.ng-select.form-control
  &.ng-select-multiple
    min-height: calc(2.25rem + 2px)
    height: auto

    .ng-value-container
      padding-right: 7px
      max-width: 100%
      min-width: 0

      .ng-placeholder
        top: 0px
        position: relative !important

      .ng-value
        display: flex
        align-items: center
        // 10px is needed because there is a hidden element which creates empty line when selected item takes 100% of available width
        max-width: calc(100% - 10px)
        min-width: 20px
        // IE11 Fix
        border: .5px solid dropdowns.$th-multiselect-label-border
        background-color: dropdowns.$th-multiselect-label-background
        color: dropdowns.$th-multiselect-label-color
        font-family: dropdowns.$th-dropdown-selected-item-font

        .ng-value-label
          margin-right: 0
          border-right: .5px solid dropdowns.$th-multiselect-label-separator
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden
          vertical-align: middle
          position: relative
          z-index: 2
        &.ng-value-disabled
          border-color: dropdowns.$th-dropdown-disabled-border-color
          background-color: dropdowns.$th-dropdown-disabled-bg
          color: dropdowns.$th-dropdown-disabled-border-color
          .ng-value-label
            border-right-color: dropdowns.$th-dropdown-disabled-border-color
          .ng-value-icon
            display: inline !important
            pointer-events: none
        .ng-value-icon
          font-size: .7rem
          position: relative
          bottom: 1px
          &:hover
            background-color: transparent

[dir="rtl"]
  .ng-select.form-control
    &.ng-select-multiple .ng-value-container
      .ng-value
        text-align: right
        padding-right: 0
        padding-left: 20px

        .ng-value-label
          padding-right: 5px
          margin-right: 0
          padding-left: 0
          margin-left: 0
          border-left: .5px solid dropdowns.$th-multiselect-label-border
          border-right: none

        .ng-value-icon
          margin-right: 2px

.ng-select.form-control
  &.option-no-padding
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option
      padding: 0
