
%clearVerticalPadding
  padding-top: 0 !important
  padding-bottom: 0 !important

%clearHorizontalPadding
  padding-left: 0 !important
  padding-right: 0 !important

.flex
  display: flex

.space-between
  display: flex
  justify-content: space-between

.justify-end
  justify-content: flex-end

.vp-0
  @extend %clearVerticalPadding

.hp-0
  @extend %clearHorizontalPadding
