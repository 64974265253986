@use "styles/variables/icons"
@use "styles/variables/labels"
@use "styles/variables/colors"
@use "styles/variables/fonts"
@use "styles/variables"
@use "styles/lte/lte-index"

@use "styles/vendor/vendor.index"
@use "styles/forms/forms.index"
@use "styles/components/components.index"
@use "styles/flag-icon"
@use "styles/utility-classes"
@use "styles/branding"
@use "styles/typography"
/* There is a new file with defined colors - colors.ts.
 * Path to file: demica\libs\core\core\src\lib\styling */


html
  font-size: fonts.$th-font-size-base

body
  font-family: fonts.$th-font-family-regular
  text-rendering: optimizeLegibility
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: variables.$th-font-color

ul
  padding-left: 1.5em
  margin: 0

a
  text-decoration: none

  &:hover
    text-decoration: none

a.trf-link, .trf-link
  color: colors.$th-color-primary-light
  font-family: fonts.$th-font-family-semi-bold
  cursor: pointer
  text-decoration: none

  &:hover
    color: variables.$th-hover

img
  -ms-interpolation-mode: bicubic

.hide
  display: none !important

.inactive-icon
  color: icons.$th-action-icon-disabled-color

.section-separator
  margin-top: variables.$panel-section-divider-margin

.section-title
  padding-top: .25em
  border-bottom: 1px solid variables.$demica-light-gray
  margin-bottom: 1.5rem

.section-header-tooltip
  display: flex

.text-wrap
  white-space: normal
  text-overflow: clip
  word-wrap: break-word

.bold
  font-family: fonts.$th-font-family-bold

ngp-svg-icon
    + span, + a
      margin-left: .25em

// Loading page with cubes
.loading
  opacity: 0
  transition: opacity .8s ease-in-out
  position: fixed
  height: 100%
  width: 100%
  top: 0
  left: 0
  background: colors.$th-color-white
  z-index: -1

trf-root:empty + .loading
  opacity: 1
  z-index: 100

  h1
    color: variables.$th-font-color
    position: absolute
    top: 50%
    width: 100%
    text-align: center
    transform: translate(0, -50%)

trf-root
  @media(max-width: variables.$screen-sm)
    display: block
    overflow: hidden
    height: 100vh

.trf-columns
  display: flex
  flex: 1
  flex-direction: row

  .trf-column
    display: flex
    flex: 1
    flex-direction: column

    &:not(:last-child)
      padding-right: 15px

[dir="rtl"]
  .list-unstyled
    padding-right: 0

  .trf-columns
    .trf-column
      &:not(:last-child)
        padding-left: 15px
        padding-right: 0

  ngp-svg-icon
    + span, + a
      margin-left: 0
      margin-right: .25em

  ul
    padding-left: 0
    padding-right: 1.5em

legend
  font-size: labels.$th-form-label-font-size

[role="button"]
  cursor: pointer
