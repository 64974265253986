$th-slidein-action-item-bg: var(--trf-slidein-action-item-bg)
$th-slidein-action-item-border: var(--trf-slidein-action-item-border)
$th-slidein-action-item-color: var(--trf-slidein-action-item-color)
$th-slidein-action-item-font-size: var(--trf-slidein-action-item-font-size)
$th-slidein-action-item-hoover-bg: var(--trf-slidein-action-item-hoover-bg)
$th-slidein-action-item-hoover-color: var(--trf-slidein-action-item-hoover-color)
$th-slidein-action-item-inner-border-color: var(--trf-slidein-action-item-inner-border-color)
$th-slidein-action-item-size: var(--trf-slidein-action-item-size)
$th-slidein-action-item-disabled-color: var(--_demica-light-dark-gray)
$th-slidein-bg: var(--trf-slidein-bg)
$th-slidein-body-border: var(--trf-slidein-border)
$th-slidein-border-color: var(--trf-slidein-border-color)
$th-slidein-border-width: var(--trf-slidein-border-width)
$th-slidein-child-shift: var(--trf-slidein-child-shift)
$th-slidein-glass-applier-bg: var(--trf-slidein-glass-applier-bg)
$th-slidein-header-border-color: var(--trf-slidein-header-border-color)
$th-slidein-header-color: var(--trf-slidein-header-color)
$th-slidein-header-font-size: var(--trf-slidein-header-font-size)
$th-slidein-header-padding: var(--trf-slidein-header-padding)
$th-slidein-content-padding: var(--trf-slidein-content-padding)

$th-slidein-footer-padding: var(--trf-slidein-footer-padding)
$th-slidein-footer-border: var(--trf-slidein-footer-border)

$exit-width: 41px