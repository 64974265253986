$th-modal-bg: var(--trf-modal-bg)
$th-modal-border: var(--trf-modal-border)
$th-modal-border-radius: var(--trf-modal-border-radius)
$th-modal-section-bg: var(--trf-modal-section-bg)
$th-modal-section-border-color: var(--trf-modal-section-border)
$th-modal-icon-color: var(--trf-modal-icon-color)
$th-modal-backdrop-color: var(--trf-modal-backdrop-color)
$th-modal-backdrop-opacity: var(--trf-modal-backdrop-opacity)
$th-modal-shadow-y-offset: var(--trf-modal-shadow-y-offset)
$th-modal-shadow-blur: var(--trf-modal-shadow-blur)
$th-modal-shadow-color: var(--trf-modal-shadow-color)
$th-modal-header-font-size: var(--trf-modal-header-font-size)
$th-modal-header-color: var(--trf-modal-header-color)
$th-modal-close-icon-color: var(--trf-modal-close-icon-color)
$th-modal-close-icon-font-size: var(--trf-modal-close-icon-font-size)
$th-modal-control-divider: var(--trf-modal-control-divider)
$th-modal-bg: var(--trf-modal-bg)
$th-modal-body-padding: var(--trf-modal-body-padding)
