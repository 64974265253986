@use "styles/variables"
@use "styles/variables/tables"
@use "styles/variables/states"

table
  table-layout: fixed
  &.auto-layout
    table-layout: auto

.table
  th, td
    padding: tables.$th-table-cell-padding

.table-bordered-header
  thead
    tr
      th, td
        border-top: none

  tbody
    tr td
      border-top: none
      border-bottom: none

.two-tables-container
  display: flex
  align-items: flex-start

  table
    width: 50%

  td
    white-space: pre-wrap
    text-overflow: initial
    vertical-align: top

td
  white-space: wrap
  overflow: hidden
  text-overflow: ellipsis

  &.date, &.number, &.percentage
    text-align: right

  &.status
    text-align: center

thead
  > span
    display: none

[dir="rtl"]
  td
    text-align: right

    &.date, &.number, &.percentage
      text-align: left

    &.status
      text-align: center

@media(max-width: variables.$screen-lg)
  .two-tables-container
    flex-wrap: wrap

    table
      width: 100%
