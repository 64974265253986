
$th-table-header-padding: var(--trf-table-header-padding)
$th-table-header-dark-color: var(--trf-table-header-dark-color)
$th-table-header-border-bottom: var(--trf-table-header-border-bottom)
$th-table-header-bg-color: var(--trf-table-header-bg-color)
$th-table-header-divider: var(--trf-table-header-divider)
$th-table-header-cell-display: var(--trf-table-header-cell-display)
$th-table-header-cell-direction: var(--trf-table-header-cell-direction)
$th-table-header-cell-justify: var(--trf-table-header-cell-justify)
$th-table-sort-indicator-color: var(--trf-table-sort-indicator-color)

$th-table-row-bg: var(--trf-table-row-bg)
$th-table-row-divider: var(--trf-table-row-divider)
$th-table-hover-bg: var(--trf-table-hover-bg)
$th-table-cell-hover-bg: var(--trf-table-cell-hover-bg)
$th-table-border-hover-x: var(--trf-table-border-hover-x)
$th-table-border-hover-y: var(--trf-table-border-hover-y)

$th-table-cell-padding: var(--trf-table-cell-padding)
$th-table-cell-divider: var(--trf-table-cell-divider)
$th-table-cell-actions-alignment: var(--trf-table-cell-actions-alignment)
$th-table-cell-actions-alignment-rtl: var(--trf-table-cell-actions-alignment-rtl)

$toggle-select-cell-width: 70px

$default-cursor: default !important
$pointer-cursor: pointer
