/* ========== Translation from CSS vars to SASS theme vars ========== */

$th-sidebar-width: var(--trf-sidebar-width)
$th-sidebar-collapse-width: var(--trf-sidebar-collapse-width)
$th-menu-aside-padding: var(--trf-menu-aside-padding)
$th-menu-aside-bg: var(--trf-menu-aside-bg)
$th-menu-aside-border: var(--trf-menu-aside-border)
$th-menu-aside-section-title-color: var(--trf-menu-aside-section-title-color)
$th-menu-aside-section-title-font: var(--trf-menu-aside-section-title-font)
$th-menu-aside-section-title-size: var(--trf-menu-aside-section-title-size)
$th-menu-aside-section-title-spacing: var(--trf-menu-aside-section-title-spacing)
$th-menu-aside-section-title-transform: var(--trf-menu-aside-section-title-transform)
$th-menu-aside-section-border: var(--trf-menu-aside-section-border)
$th-menu-aside-item-color: var(--trf-menu-aside-item-color)
$th-menu-aside-item-font: var(--trf-menu-aside-item-font)
$th-menu-aside-item-icon-size: var(--trf-menu-aside-item-icon-size)
$th-menu-aside-item-border-width: var(--trf-menu-aside-item-border-width)
$th-menu-aside-item-active-color: var(--trf-menu-aside-item-active-color)
$th-menu-aside-item-active-border: var(--trf-menu-aside-item-active-border)