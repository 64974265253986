$th-dropdown-arrow-color: var(--trf-dropdown-arrow-color)
$th-dropdown-border-color: var(--trf-dropdown-border-color)
$th-dropdown-border-radius: var(--trf-dropdown-border-radius)
$th-dropdown-border: var(--trf-dropdown-border)
$th-dropdown-color: var(--trf-dropdown-color)
$th-dropdown-font-family: var(--trf-dropdown-font-family)
$th-dropdown-font-size: var(--trf-dropdown-font-size)
$th-dropdown-highlight-item-bg: var(--trf-dropdown-highlight-item-bg)
$th-dropdown-highlight-item-color: var(--trf-dropdown-highlight-item-color)
$th-dropdown-placeholder-color: var(--trf-dropdown-placholder-color)
// dropdown item selected
$th-dropdown-selected-item-bg: var(--trf-dropdown-selected-item-bg)
$th-dropdown-selected-item-border: var(--trf-dropdown-selected-item-border)
$th-dropdown-selected-item-color: var(--trf-dropdown-selected-item-color)
$th-dropdown-selected-item-font: var(--trf-dropdown-selected-item-font)
// dropdown disabled
$th-dropdown-disabled-color: var(--trf-dropdown-disabled-color)
$th-dropdown-disabled-option-color: var(--trf-dropdown-disabled-option-color)
$th-dropdown-disabled-label-color: var(--trf-dropdown-disabled-label-color)
$th-dropdown-disabled-border-color: var(--trf-dropdown-disabled-border-color)
$th-dropdown-disabled-bg: var(--trf-dropdown-disabled-bg)
$th-dropdown-disabled-placeholder-color: var(--trf-dropdown-disabled-placeholder-color)

$th-multiselect-label-padding: var(--trf-multiselect-label-padding)
$th-multiselect-label-border: var(--trf-multiselect-label-border)
$th-multiselect-label-separator: var(--trf-multiselect-label-separator)
$th-multiselect-label-background: var(--trf-multiselect-label-background)
$th-multiselect-label-color: var(--trf-multiselect-label-color)

$th-dropdown-option-padding: var(--trf-dropdown-option-padding, 5px 8px)
