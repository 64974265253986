/* ========== Translation from CSS vars to SASS theme vars ========== */

/* Status Icons */
$th-status-icon-success-color: var(--trf-status-icon-success-color)
$th-status-icon-warning-color: var(--trf-status-icon-warning-color)
$th-status-icon-error-color: var(--trf-status-icon-error-color)
$th-status-icon-info-color: var(--trf-status-icon-info-color)
$th-status-icon-font-size: var(--trf-status-icon-font-size)

/* Table Icons */
$th-sort-indicator-icon-color: var(--trf-sort-indicator-icon-color)
$th-table-icon-size: var(--trf-table-icon-size)

/* Action Icons */
$th-action-icon-color: var(--trf-action-icon-color)
$th-action-icon-disabled-color: var(--trf-action-icon-disabled-color)
$th-action-icon-hover-color: var(--trf-action-icon-hover-color)
$th-action-icon-processed-color: var(--trf-action-icon-processed-color)
