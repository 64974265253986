@use "styles/variables/radio"
@use "styles/variables/transitions"

.form-radio-button-input
  opacity: 0
  width: 0
  height: 0
  line-height: 0

  &:focus + label::before
    outline: auto

  + label
    display: inline-block
    vertical-align: middle
    cursor: pointer
    position: relative
    padding-left: 2em
    margin-right: 10px
    &::before, &::after
      content: ''
      position: absolute
      left: 0
      top: 0
      margin: auto
      display: inline-block
      vertical-align: middle
      background-color: radio.$th-radio-bg
      height: radio.$th-radio-size
      width: radio.$th-radio-size
      border-radius: 50%
      transition: background-color .2s transitions.$th-transition-fn

    &::before
      background-color: radio.$th-radio-frame-border-color

    &::after
      transform: scale(radio.$th-radio-frame-border-scale)
      background-color: radio.$th-radio-bg

  &:checked
    + label
      &::after
        border: radio.$th-radio-check-border-size solid radio.$th-radio-bg
        background-color: radio.$th-radio-check-color

  &:disabled
    + label
      opacity: .3
      color: radio.$th-radio-disabled-bg
      &::before
        background-color: radio.$th-radio-disabled-bg
    &:checked
      + label
        &::after
          background-color: radio.$th-radio-disabled-check-color

html[dir="rtl"]
  .form-radio-button-input
    + label
      padding-left: 0
      padding-right: 2em
      margin-right: 0
      margin-left: 10px
      &::before, &::after
        left: auto
        right: 0
