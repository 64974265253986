@use "styles/variables/labels"

form

  label.required::after
    margin-left: .25em
    content: '*'
    color: labels.$th-form-label-asterisk

  .column-label
    text-align: left

    & > label
      font-size: labels.$th-form-label-font-size
      margin: 0 0 labels.$th-form-label-bottom-margin labels.$th-form-label-offset

    &.row-disabled
      label, label.required::after
        color: labels.$th-form-label-disabled-color

[dir="rtl"]
  form
    .column-label
      text-align: right

      & > label
        margin: 0 labels.$th-form-label-offset labels.$th-form-label-bottom-margin 0
