@use "../mixins/drag-and-drop-nested"
@use "../variables/icons"

$drag-and-drop-nested-border: 1px solid var(--ngp-drag-and-drop-nested-item-border-color)
$drag-and-drop-nested-line: 1px solid var(--ngp-drag-and-drop-nested-line-color)
$drag-and-drop-nested-max-nesting: 8 // max level that is used in the entire application

$drag-and-drop-nested-line-height: calc(var(--ngp-drag-and-drop-nested-line-height) + var(--ngp-drag-and-drop-nested-row-gap))
$drag-and-drop-nested-width: calc(0.5 * var(--ngp-drag-and-drop-nested-padding))
$drag-and-drop-nested-column-padding: 15px

.ngp-dnd-container
    display: grid
    grid-column-start: 1
    font-size: var(--ngp-drag-and-drop-nested-font-size)
    max-width: 100%
    overflow-x: auto

    .cdk-drag-placeholder
        opacity: 0.5

    .cdk-drop-list-dragging, .ngp-dnd-item:hover
        cursor: move

.ngp-dnd-list-container
    padding-bottom: 15px
    outline-offset: -1px // fix for ff outline issue with overflow (tab navigation)

.ngp-dnd-list, .ngp-dnd-element, .ngp-dnd-item, .ngp-dnd-list-container, .ngp-dnd-row-element, .ngp-dnd-column-container
    @include drag-and-drop-nested.dragAndDropGridElement(var(--ngp-drag-and-drop-nested-row-gap))

.ngp-dnd-column-container
    margin-bottom: calc(-1 * var(--ngp-drag-and-drop-nested-row-gap))

.ngp-dnd-list
    padding-left: 0px

.ngp-pre-dragging
    > .ngp-dnd-element
        &::after
            height: $drag-and-drop-nested-line-height !important

.ngp-dnd-item:not(.ngp-dnd-item-first)
    .ngp-dnd-element
        position: relative

        &::after
            @include drag-and-drop-nested.dragAndDropVerticalLine($drag-and-drop-nested-line)

    &:last-child
        > .ngp-dnd-element
            &::after
                height: $drag-and-drop-nested-line-height

        &:empty
            &::after
                height: 0px

    @for $i from 1 through $drag-and-drop-nested-max-nesting
        &.ngp-dnd-item-#{$i}
            .ngp-dnd-element
                &::after
                    left: calc($drag-and-drop-nested-width + ($i - 1) * var(--ngp-drag-and-drop-nested-padding))

            .ngp-dnd-column-first
                padding-left: calc($i * var(--ngp-drag-and-drop-nested-padding))

.ngp-dnd-column
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    font-size: var(--ngp-drag-and-drop-nested-font-size)

    &:not(.ngp-dnd-column-first)
        background: var(--ngp-drag-and-drop-nested-item-backgroud-color)
        border-top: $drag-and-drop-nested-border
        border-bottom: $drag-and-drop-nested-border
        padding: $drag-and-drop-nested-column-padding

    &.ngp-dnd-column-first
        display: flex
        padding: 0 // firefox fix -> some padding visible in ff, in chrome this space is taken by a flex child

        .ngp-dnd-grab-icon
            background: var(--ngp-drag-and-drop-nested-item-backgroud-color)
            border-top: $drag-and-drop-nested-border
            border-bottom: $drag-and-drop-nested-border
            border-left: $drag-and-drop-nested-border
            padding: $drag-and-drop-nested-column-padding
            flex: 0 1 100%
            position: relative
            display: flex
            align-items: center
            color: icons.$th-action-icon-color

    &.ngp-dnd-column-last
        border-right: $drag-and-drop-nested-border
        text-align: center
        text-overflow: clip
        border-radius: var(--ngp-drag-and-drop-nested-border-radius)

.ngp-dnd-element:not(.ngp-dnd-element-0)
    .ngp-dnd-grab-icon
        &::before
            @include drag-and-drop-nested.dragAndDropHorizontalLine($drag-and-drop-nested-line, $drag-and-drop-nested-width)

// dragging styles
.cdk-drag-preview
    .ngp-dnd-list, .ngp-dnd-element, .ngp-dnd-item
        @include drag-and-drop-nested.dragAndDropGridElement()

    .ngp-dnd-column:not(.ngp-dnd-column-first), .ngp-dnd-grab-icon
        border-color: var(--ngp-drag-and-drop-nested-dragged-border-color) !important
        background-color: var(--ngp-drag-and-drop-nested-dragged-backgroud-color) !important

    &.ngp-dnd-element
        > .ngp-dnd-row-element
            .ngp-dnd-column-first
                .ngp-dnd-grab-icon::before
                    display: none

        @for $i from 1 through $drag-and-drop-nested-max-nesting
            &.ngp-dnd-element-#{$i}
                .ngp-dnd-column-first
                    padding-left: calc($i * var(--ngp-drag-and-drop-nested-padding)) !important

            .ngp-dnd-item-#{$i}
                .ngp-dnd-column-first
                    padding-left: calc($i * var(--ngp-drag-and-drop-nested-padding)) !important

// dragging placeholder
@include drag-and-drop-nested.dragAndDropPlaceholderInsert($drag-and-drop-nested-max-nesting, var(--ngp-drag-and-drop-nested-padding))

[dir="rtl"]
    // rtl dragging styles
    .cdk-drag-preview.ngp-dnd-element
        @for $i from 1 through $drag-and-drop-nested-max-nesting
            &.ngp-dnd-element-#{$i}
                .ngp-dnd-column-first
                    padding-left: 0 !important
                    padding-right: calc($i * var(--ngp-drag-and-drop-nested-padding)) !important

            .ngp-dnd-item-#{$i}
                .ngp-dnd-column-first
                    padding-left: 0 !important
                    padding-right: calc($i * var(--ngp-drag-and-drop-nested-padding)) !important

    .ngp-dnd-list
        padding: 0 !important

    .ngp-dnd-item:not(.ngp-dnd-item-first)
        @for $i from 1 through $drag-and-drop-nested-max-nesting
            &.ngp-dnd-item-#{$i}
                .ngp-dnd-element
                    &::after
                        right: calc($drag-and-drop-nested-width + ($i - 1) * var(--ngp-drag-and-drop-nested-padding))

                    .ngp-dnd-column-first
                        padding-left: 0
                        padding-right: calc($i * var(--ngp-drag-and-drop-nested-padding))

    .ngp-dnd-column
        &.ngp-dnd-column-first
            .ngp-dnd-grab-icon
                border-left: none
                border-right: $drag-and-drop-nested-border

        &.ngp-dnd-column-last
            border-right: none
            border-left: $drag-and-drop-nested-border

    .ngp-dnd-element:not(.ngp-dnd-element-0)
        .ngp-dnd-grab-icon
            &::before
                @include drag-and-drop-nested.dragAndDropHorizontalLine($drag-and-drop-nested-line, $drag-and-drop-nested-width, true)

    // rtl dragging placeholder
    @include drag-and-drop-nested.dragAndDropPlaceholderInsert($drag-and-drop-nested-max-nesting, var(--ngp-drag-and-drop-nested-padding), true)
