/* ========== Translation from CSS vars to SASS theme vars ========== */

$th-header-logo: var(--trf-brand-logo)
$th-header-logo-width: var(--trf-header-logo-width)
$th-header-logo-height: var(--trf-header-logo-height)
$th-header-logo-align: var(--trf-header-logo-align)
$th-header-icon: var(--trf-brand-icon)
$th-header-icon-width: var(--trf-header-icon-width)
$th-header-icon-height: var(--trf-header-icon-height)

$th-header-background-color: var(--trf-header-background-color)
$th-header-font-color: var(--trf-header-font-color)
$th-header-dropdown-header-font-color: var(--trf-header-dropdown-header-font-color)
$th-header-dropdown-box-shadow-color: var(--trf-header-dropdown-box-shadow-color)
$th-header-toggle-button-color: var(--trf-header-toggle-button-color)
$th-header-chevron-icon-color: var(--trf-header-chevron-icon-color)
$th-header-dropdown-border-color: var(--trf-header-dropdown-border-color)
$th-header-signout-icon-color: var(--trf-header-signout-icon-color)
$th-header-text-transform: var(--trf-header-dropdown-header-text-transform)
$th-header-dropdown-active-element-color: var(--trf-header-dropdown-active-element-color)
$th-header-dropdown-active-element-border-color: var(--trf-header-dropdown-active-element-border-color)
$th-header-dropdown-active-element-border-width: var(--trf-header-dropdown-active-element-border-width)
$th-header-profile-dropdown-text-transform: var(--trf-header-profile-dropdown-text-transform)
$th-header-profile-dropdown-username-font-weight: var(--trf-header-profile-dropdown-username-font-weight)

/* Header Alerts */
$th-header-alert-status-performing: var(--trf-header-alert-status-performing)
$th-header-alert-status-watch-list: var(--trf-header-alert-status-watch-list)
$th-header-alert-status-at-risk: var(--trf-header-alert-status-at-risk)
$th-header-alert-missing-files: var(--trf-header-alert-missing-files)
