@use "../variables/colors"

/* override some hardcoded values in yfiles controls */
.yfiles-labeleditbox-container
  border: none !important

.yfiles-labeleditbox
  padding: 10px !important
  color: colors.$th-color-primary-light
  min-width: 190px
