tree-node-drop-slot
  display: none

.node-content-wrapper:hover
  background-color: transparent
  box-shadow: none

.node-wrapper
  display: flex
  align-items: center
  padding-bottom: 10px
  cursor: default