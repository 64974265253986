@use "styles/variables/inputs"

ng-select.form-control, .form-control
  &:focus
    box-shadow: none
    border: inputs.$th-input-border
    outline: none
  &:focus-visible
    outline: 1px solid Highlight
    outline: 1px solid -webkit-focus-ring-color
