/* ========== Translation from CSS vars to SASS theme vars ========== */
$th-inline-notification-msg-icon-display: var(--ngp-inline-notification-msg-icon-display)
$th-inline-notification-msg-icon-margin-right: var(--ngp-inline-notification-msg-icon-margin-right)
$th-inline-notification-msg-icon-error-color: var(--ngp-inline-notification-msg-icon-error-color)
$th-inline-notification-msg-icon-warning-color: var(--ngp-inline-notification-msg-icon-warning-color)
$th-inline-notification-msg-color: var(--ngp-inline-notification-msg-color, inherit)
$th-inline-notification-msg-warning-color: var(--ngp-inline-notification-msg-warning-color, inherit)
$th-inline-notification-msg-height: var(--ngp-inline-notification-msg-height)
$th-inline-notification-msg-margin-top: var(--ngp-inline-notification-msg-margin-top)
$th-inline-notification-font-size: var(--ngp-inline-notification-font-size)

$th-context-notification-font-size: var(--ngp-context-notification-font-size)
$th-context-notification-border-radius: var(--ngp-context-notification-border-radius)
$th-context-notification-padding: var(--ngp-context-notification-padding)
$th-context-notification-border: var(--ngp-context-notification-border)
$th-context-notification-icon-display: var(--ngp-context-notification-icon-display)
$th-context-notification-icon-size: var(--ngp-context-notification-icon-size)
$th-context-notification-margin: var(--ngp-context-notification-margin)
$th-context-notification-icon-margin-right: var(--ngp-context-notification-icon-margin-right)

$th-context-notification-warning-bg: var(--ngp-context-notification-warning-bg)
$th-context-notification-warning-border-color: var(--ngp-context-notification-warning-border-color)
$th-context-notification-warning-icon-color: var(--ngp-context-notification-warning-icon-color)
$th-context-notification-warning-font-color: var(--ngp-context-notification-warning-font-color)

$th-context-notification-error-bg: var(--ngp-context-notification-error-bg)
$th-context-notification-error-border-color: var(--ngp-context-notification-error-border-color)
$th-context-notification-error-icon-color: var(--ngp-context-notification-error-icon-color)
$th-context-notification-error-font-color: var(--ngp-context-notification-error-font-color)

$th-context-notification-info-bg: var(--ngp-context-notification-info-bg)
$th-context-notification-info-border-color: var(--ngp-context-notification-info-border-color)
$th-context-notification-info-icon-color: var(--ngp-context-notification-info-icon-color)
$th-context-notification-info-font-color: var(--ngp-context-notification-info-font-color)

$th-context-notification-success-bg: var(--ngp-context-notification-success-bg)
$th-context-notification-success-border-color: var(--ngp-context-notification-success-border-color)
$th-context-notification-success-icon-color: var(--ngp-context-notification-success-icon-color)
$th-context-notification-success-font-color: var(--ngp-context-notification-success-font-color)
